import * as React from 'react';
import Cookies from 'js-cookie';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import GetFriendsList from './GetFriendsList';
import GetSpotifyUserPlayingTrack from './GetSpotifyUserPlayingTrack';
import GetNormalizedRecentlyPlayed from './GetNormalizedRecentlyPlayed';
import GetNormalizedTopTracks from './GetNormalizedTopTracks';

function loggedIn() {
  if (Cookies.get('apiToken')) {
    return true
  } else {
    return false
  }
}

const EUAAndPP = () => {
  if (Cookies.get('apiToken')) {
    console.log('User is authenticated');
  } else {
    alert("Redirecting to AWS Cognito because you don't have a cookie.");
    window.location.replace('https://' + process.env.REACT_APP_COGNITO_AUTH_DOMAIN + '/login?' +
                'response_type=code&' +
                'client_id=' + process.env.REACT_APP_COGNITO_CLIENT_ID + '&' +
                'redirect_uri=' + process.env.REACT_APP_APP_URL + '/callback');
    console.log('User is not authenticated');
  }

  const htmlString = `
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Terms of Service & Privacy Policy</title>
    <style>
        body {
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
            line-height: 1.6;
            max-width: 800px;
            margin: 0 auto;
            padding: 20px;
            color: #333;
        }
        h1 {
            color: #2c3e50;
            border-bottom: 2px solid #eee;
            padding-bottom: 10px;
        }
        h2 {
            color: #34495e;
            margin-top: 30px;
        }
        .section {
            margin-bottom: 40px;
        }
        .last-updated {
            color: #666;
            font-style: italic;
            margin-bottom: 30px;
        }
    </style>
</head>
<body>
    <h1>Terms of Service & Privacy Policy</h1>
    <p class="last-updated">Last Updated: January 28, 2025</p>

    <div class="section">
        <h2>1. Terms of Service</h2>
        
        <h3>1.1 Agreement to Terms</h3>
        <p>By accessing and using this application, you agree to be bound by these Terms of Service. If you disagree with any part of these terms, you may not access or use the application.</p>

        <h3>1.2 Use License</h3>
        <p>Permission is granted to temporarily access and use the application for personal, non-commercial purposes. This is the grant of a license, not a transfer of title, and under this license you may not:</p>
        <ul>
            <li>modify or copy the materials</li>
            <li>use the materials for any commercial purpose</li>
            <li>attempt to decompile or reverse engineer any software contained in the application</li>
            <li>remove any copyright or other proprietary notations</li>
            <li>transfer the materials to another person or "mirror" the materials on any other server</li>
        </ul>

        <h3>1.3 Disclaimer</h3>
        <p>The materials within the application are provided on an 'as is' basis. We make no warranties, expressed or implied, and hereby disclaim and negate all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.</p>

        <h3>1.4 Limitations</h3>
        <p>In no event shall we or our suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the application.</p>
    </div>

    <div class="section">
        <h2>2. Privacy Policy</h2>

        <h3>2.1 Information We Collect</h3>
        <p>We collect information that you provide directly to us, including:</p>
        <ul>
            <li>Account information (such as name and email address)</li>
            <li>Profile information</li>
            <li>Content you submit or create through the application</li>
            <li>Communication preferences</li>
        </ul>

        <h3>2.2 Automatically Collected Information</h3>
        <p>When you access or use our application, we automatically collect:</p>
        <ul>
            <li>Device information (such as your hardware model, operating system version, unique device identifiers)</li>
            <li>Log information (such as access times, hardware and software information)</li>
            <li>Usage data (such as features you use and interactions with content)</li>
        </ul>

        <h3>2.3 How We Use Your Information</h3>
        <p>We use the information we collect to:</p>
        <ul>
            <li>Provide, maintain, and improve our services</li>
            <li>Process your transactions</li>
            <li>Send you technical notices and support messages</li>
            <li>Communicate with you about products, services, and events</li>
            <li>Monitor and analyze trends and usage</li>
            <li>Detect, investigate, and prevent fraud and other harmful activities</li>
        </ul>

        <h3>2.4 Information Sharing</h3>
        <p>We do not share your personal information with third parties except:</p>
        <ul>
            <li>With your consent</li>
            <li>To comply with legal obligations</li>
            <li>To protect our rights and property</li>
            <li>In connection with a business transfer or transaction</li>
        </ul>

        <h3>2.5 Data Security</h3>
        <p>We implement reasonable security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction.</p>

        <h3>2.6 Your Rights</h3>
        <p>You have the right to:</p>
        <ul>
            <li>Access your personal information</li>
            <li>Correct inaccurate or incomplete information</li>
            <li>Request deletion of your information</li>
            <li>Opt out of marketing communications</li>
            <li>Lodge a complaint with applicable data protection authorities</li>
        </ul>
    </div>

    <div class="section">
        <h2>3. Changes to Terms & Privacy Policy</h2>
        <p>We reserve the right to modify these terms and privacy policy at any time. We will notify users of any material changes via email or through the application. Your continued use of the application after such modifications constitutes your acceptance of the updated terms and privacy policy.</p>
    </div>
</body>
</html>
  `;
  return (
    <div dangerouslySetInnerHTML={{ __html: htmlString }} />
  );
  };
  
  export default EUAAndPP;