import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';
import { CircularProgress } from '@mui/material';
import spotifyLogo from './Spotify_Full_Logo_RGB_Green.png';
import appleMusicLogo from './256px-Apple_Music_icon.svg.png'
import missingImage from './missing_image.jpg'

const handleRowClick = (
  params, // GridRowParams
  event, // MuiEvent<React.MouseEvent<HTMLElement>>
  details, // GridCallbackDetails
) => {
  var win = window.open('http://open.spotify.com/track/' + params.id, '_blank');
  win.focus();
};

const columns = [
  { field: 'id', headerName: 'Track Id', width: 150 },
  {
    field: 'albumImage',
    headerName: '',
    width: 75,
    renderCell: (params) => {
      if (params.value === undefined) {
       return(<img alt='Missing' src={missingImage} width='50px' />)
      } else if (params.value === '') {
        return(<img alt='Missing' src={missingImage} width='50px' />)
      } else {
        return(<img alt='Artwork' src={params.value} width='50px' />)
      }
    },
  },
  {
    field: 'title',
    headerName: 'Title',
    width: 100
  },
  {
    field: 'artist',
    headerName: 'Artist(s)',
    width: 100
  },
  {
    field: 'count',
    headerName: '# Plays',
    width: 100,
    type: "number"
  },
  {
    field: 'musicService',
    headerName: 'Service',
    width: 75,
    renderCell: (params) => {
      if (params.value === 'AppleMusic') {
       return(<img alt='Apple Music' src={appleMusicLogo} width='50px' />)
      } else if (params.value === 'Spotify') {
        return(<img alt='Spotify' src={spotifyLogo} width='50px' />)
      }
    },
  },
];

function normalizedTopTracksData(jsonData) {
  return (jsonData.map(item => {
    var url = item.albumImageUrl || '';
    url = url.replace('{w}', '50');
    url = url.replace('{h}', '50');
    return ({
      id: item.trackId,
      title: item.trackName,
      artist: item.artist,
      count: item.trackCount,
      musicService: item.musicService,
      albumImage: url
    });
  }))
}

function GetNormalizedTopTracks(perspective) {
  const [loading, setLoading] = useState(true);
  const [localFriend, setLocalFriend] = useState(Cookies.get('currentFriendSub'));
  const [currentUserTopTracksData, setCurrentUserTopTracksData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {

        const response = await fetch('https://' + process.env.REACT_APP_API_DOMAIN + '/v1/get-normalized-top-tracks' +
          ((perspective === 'CurrentUser') ? '' : '?sub=' + localFriend ), {
          headers: {
            "Authorization": "Bearer " + Cookies.get('apiToken')
          },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        setCurrentUserTopTracksData(normalizedTopTracksData(jsonData))
        // console.log(jsonData)
      } catch (error) {
        setCurrentUserTopTracksData([{
          id: '1',
          albumImage: '',
          title: 'Error',
          artist: '',
          count: '',
          musicService: ''
        }]);
        console.error('Error fetching top tracks:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    const intervalId = setInterval(fetchData, 60000); // Fetch every 60 seconds

    return () => clearInterval(intervalId); // Cleanup on unmount

  }, [perspective, localFriend]);

  // Update friend with every render when its value has changed.
  const friendCookie = Cookies.get("currentFriendSub");
  if (localFriend !== friendCookie ) {
    setLocalFriend(friendCookie);
  }

  return (
    <div>
      <Typography variant="h5" component="h2" sx={{ mb: 2 }}>
      Your {((perspective === 'Friend') ? 'friend, ' + Cookies.get("currentFriendName") + '\'s': '')} top tracks
      </Typography>
      {loading && <div><CircularProgress /></div>}
      {!loading &&
      <div>
        <Box sx={{ height: '100%', width: '100%' }}>
          <DataGrid
            getRowHeight={() => 'auto'}
            onRowClick={handleRowClick}
            rows={currentUserTopTracksData}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },            
              },
              sorting: {
                sortModel: [{ field: 'count', sort: 'desc' }],
              },
              columns: {
                columnVisibilityModel: {
                  id: false,
                },
              },
            }}
            pageSizeOptions={[5]}
          />
        </Box>
      </div>}
    </div>
  )
}

export default GetNormalizedTopTracks;